import React, { useCallback, useEffect, useState } from 'react'
import './uploadcv.css'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { useDropzone } from "react-dropzone"
import { uploadCvApi } from '../services'
import LoadingModal from '../components/modals/LoadingModal'
import { useNavigate } from 'react-router-dom'
import { notify } from '../components/actions/CommonActions'

const UploadCV = () => {
    const [dataURL, setDataURL] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [passedInfo, setPassedInfo] = useState({
      support_info: ""
    })
    const navigate = useNavigate()

    

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach(file => {
          const reader = new FileReader()
          reader.onabort = () => console.log("file reading was aborted")
          reader.onerror = () => console.log("file reading has failed")
          reader.onload = () => {
            const binaryStr = reader.result
            setDataURL(binaryStr)
          }
          reader.readAsDataURL(file)
        })
      }, [])

      const {
        getRootProps,
        acceptedFiles,
        getInputProps,
        isDragActive,
      } = useDropzone({ onDrop })

      // const selectedFile = acceptedFiles[0]
      useEffect(() => {
        const initiate = async () => {
            if (acceptedFiles && acceptedFiles[0]) {
                const selectedFile = acceptedFiles[0]
                setSelectedFile(selectedFile)
                
                const formData = new FormData()
                formData.append("file", selectedFile)
                setLoading(true)
                
                try {
                    const res = await uploadCvApi(formData)
                    console.log('res upload ', res)
                    notify("success", "File Uploaded", "File uploaded successfully.")
                    setPassedInfo(prev => ({
                        ...prev,
                        cv_url: res?.data?.file_url
                    }))
                } catch (err) {
                    console.log('err upload ', err)
                    notify("danger", "OOPS", err?.response?.data?.message)
                } finally {
                    setLoading(false)
                }
            }
        }
        initiate()
    }, [acceptedFiles])

      // console.log('selectedFile ',selectedFile);
      const handleSubmit = async (e) =>{
        e.preventDefault()
        console.log(passedInfo);
        if(passedInfo?.cv_url){
        navigate('/uploadcv-info', {
          state: passedInfo
        });
      }
      }

      const handleChange= (e) =>{
        const {name, value} = e.target;
        setPassedInfo((prev) => ({...prev, [name]: value}))
      }
    

  return (
    <div>
        <Header />
        <div className="viewQuizPage">
        <div className="bodyTextHolderFormQuiz">
        <p className="topicUploadCv" style={{textAlign: 'center'}}>Upload your cv here</p>
        <p className='subTextTopicUploadCv'>Select which way you want to upload your CV</p>
            {!selectedFile && <>
        <div className='uploadViewDivCV' {...getRootProps()}>
        <input {...getInputProps()} />
            <img src={require('../assets/fileupload.png')} width="50px" height="50px" />
            <p className='clickTextUploadCv'><span className='clickBolderUploadCV'>Click to upload</span> or drag and drop</p>
            <p className='fileTypeUploadCV'>PDF, DOC, DOCX (max. 800x400px)</p>
            <div className='orDivHolderUploadCv'>
                <div className='lineOrUploadCv'></div>
                <p className='fileTypeUploadCV' style={{fontWeight: 'bold'}}>OR</p>
                <div className='lineOrUploadCv'></div>
            </div>
            <label for='browseFile' className='browseButtonUploadCv'>Browse Files</label>
            <input id="browseFile" type="file" style={{display: 'none'}} />
        </div>
            </>}

            {selectedFile && <>
        <div className='uploadViewDivCV'>
              <p className='clickTextUploadCv'><span className='clickBolderUploadCV'>File uploaded</span></p>
              <p>File name: {selectedFile?.name}</p>
              <button onClick={()=> setSelectedFile(null)} className='browseButtonUploadCv' style={{marginTop: "10px", border: "1px solid red", color: "red"}}>Cancel</button>
        </div>
            </>}

        <p className='supportingInfoUploadCvTopic'>Personal statement</p>
        <p className='subTextTopicUploadCv'>Tell us things you’ve done, career path, etc</p>
        <form onSubmit={handleSubmit}>
        <textarea name="support_info" onChange={handleChange} className='supportTextAreaUploadCv' placeholder='Also include things like what you do, what you want to do, any useful information about yourself'/>
        <div style={{ display: 'flex', justifyContent:"center"}}>
        <button type="submit" className='nextButtonQuestionPage' style={{marginTop: "50px", fontSize: "16px", padding: "10px 70px"}}>Continue</button>
        </div>
        </form>
        </div>
        <LoadingModal open={loading} />
        </div>
        <Footer />
        </div>
  )
}

export default UploadCV