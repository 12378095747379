import React, { useState } from 'react'
import "./loginConsultant.css"
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';
import LoadingModal from '../components/modals/LoadingModal';
import { loginConsultant } from '../services';
import { notify } from '../components/actions/CommonActions';

const LoginConsultant = () => {
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    email: "",
    password: ""
  })
  const navigate = useNavigate()

  const handleChange = (event) =>{
    const {name, value} = event.target;
    setData((prev) => ({...prev, [name]: value }))
  }

  const handleSubmit = async (e) =>{
    e.preventDefault();
    setLoading(true)
    // console.log( "login consultant",data);
    await loginConsultant(data)
    .then(async (res)=>{
      console.log('anon res ', res);
      await localStorage.setItem('token', res?.access_token)
      await localStorage.setItem('refresh_Token', res?.refresh_token)
      await localStorage.setItem('userData', JSON.stringify(res))
      navigate('/dashboard-consultant')
    })
    .catch((err)=>{
      // console.log('err res anon ', err);
      notify("danger", "OOPS", err?.response?.data?.message);
    })
    setLoading(false)
  }


  return (
    <div className='loginConsultantAll'>
        <a href="https://www.iquire.co/" ><img src={require("../assets/cvReviewLogo.png")} className='imgLogoCvReview' /></a>
        <div className='loginOverViewImgHidden'>
            <form onSubmit={handleSubmit} className='loginViewInputLabelText'>
                <h1 style={{textAlign: "center"}}>Login with your email</h1>
                <p className='textSubLabelHeader'>Welcome back! Please enter your details. 🔐</p>
                <p className='inputLabelCvReviewLogin'>Email Address</p>
                <input className='inputTextCvReviewLogin' name="email" required onChange={handleChange} type="email" placeholder='example@email.com' />
                <p className='inputLabelCvReviewLogin'>Password</p>
                <div className='passwordDivHolderCvReview'>
                    <input type='password' name="password" required onChange={handleChange} className='passwordinputCvReview' />
                    {showPassword ? (
                    <FaRegEye
                      color="#667185"
                      size={18}
                      onClick={() => setShowConfPassword(!showConfPassword)}
                    />
                  ) : (
                    <FaRegEyeSlash
                      color="#667185"
                      size={18}
                      onClick={() => setShowConfPassword(!showConfPassword)}
                    />
                  )}
                </div>
                <Link to="/forgot-passwordCv" className='forgotPasswordCvReview'>Forgot password?</Link>

                <button
                  className="loginButton"
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Login
                </button>
            </form>

            <LoadingModal open={loading} />
            <img src={require("../assets/userIconCvReview.png")} className='userIconCvReview' />
            <img src={require("../assets/usergroupCvReview.png")} className='userGroupCvReview' />
        </div>
    </div>
  )
}

export default LoginConsultant